import React from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import HeaderCreateForm from '../../../organisms/forms/HeaderCreateForm'
import Preview from '../../../templates/Preview'
import CitizenFormPage from '../../citizens/CitizenFormPage'
import useUserPermission from '../../../../hooks/useUserPermission'
import PublishAlert from '../../../molecules/PublishAlert'

const getPreview = id => () => axios.get(`/admin/v1/forms/${id}.json`).then(res => res.data)
const updatePublishForm = id => values => axios.put(`/admin/v1/forms/${id}/publish.json`, values)

const headerParams = {
  activeStep: 3,
  hint: 'Revisa tu formulario, publícalo y comparte el enlace con tus usuarios.'
}
const informationText = {
  linkTitle: 'Enlace del formulario',
  linkHint: 'Una vez publicado el formulario, este es el enlace que deberás compartir con tus usuarios.',
  previewTitle: 'Este es el formulario que verán tus usuarios:',
  previewHint: ''
}

const FormPreview = () => {
  const { id } = useParams()
  const queryCache = useQueryClient()
  const { data: form, status } = useQuery(['forms', id], getPreview(id))
  const { mutate } = useMutation(updatePublishForm(id), {
    onSuccess: () => queryCache.invalidateQueries(['forms', id])
  })
  const urlForm = `${window.location.origin}/t/${id}`

  const { canEdit } = useUserPermission('form')
  const edit = canEdit(form) && {
    url: `/formularios/${id}/editar`,
    buttonLabel: 'Editar mi formulario'
  }

  if (status !== 'success') return null

  return (
    <Preview
      steps={<HeaderCreateForm headerParams={headerParams} />}
      publishAlert={
        !form.allow_publish && (
          <PublishAlert
            serviceName="el formulario"
            urlToRedirect={`/formularios/${id}/preguntas#privacy_policy?action=edit`}
          />
        )
      }
      data={form}
      urlToCopy={urlForm}
      publishedUpdate={mutate}
      informationText={informationText}
      edit={edit}
    >
      <CitizenFormPage isPreviewModeSelected />
    </Preview>
  )
}

export default FormPreview
