import { serialize } from 'object-to-formdata'

const firstFormDate = '22/07/2020'

const steps = [
  'Base del formulario',
  'Configuración del formulario',
  'Preguntas del formulario',
  'Vista previa y publicación'
]

const instructions = {
  withTemplate: [
    'Las preguntas en fondo <strong>gris no son editables,</strong> es decir, están tal como se muestran en tu formulario final.',
    'Si encuentras preguntas en fondo <strong>blanco, puedes editar sus alternativas de respuesta.</strong>',
    'Si la plantilla lo permite, puedes agregar más preguntas y moverlas según creas conveniente.'
  ],
  withoutTemplate: [
    'Elige el tipo de pregunta de tu formulario, agrega su contenido y selecciona si es obligatoria u opcional.',
    'Ordena las preguntas de la forma más conveniente.',
    'Si tu formulario es extenso, puedes dividirlo en varias páginas.'
  ]
}
const getInstructions = fromTemplate => (fromTemplate ? instructions.withTemplate : instructions.withoutTemplate)

const defaultConfig = {
  from_template: false,
  answers_by_email: true,
  sort_questions: true,
  add_questions: true,
  edit_delete_questions: true,
  it_costs: 'official_decide',
  auto_response: 'official_decide',
  is_trackable: 'official_decide',
  sworn_declaration: 'official_decide',
  enabled_payment_methods: false,
  enabled_payment_transfer_accounts_attributes: false
}
const getConfig = (customConfig = {}) => ({ ...defaultConfig, ...customConfig })

const initialValuesFromTemplate = template => ({
  template_id: template.id,
  name: template.name,
  subtype: template.subtype,
  hint: template.hint,
  cover_image: template.cover_image,
  it_costs: template.it_costs === 'yes',
  auto_response: template.auto_response === 'yes',
  is_trackable: template.is_trackable === 'yes',
  sworn_declaration: template.sworn_declaration === 'yes',
  steps_attributes: getStepsFromTemplate(template.steps_attributes)
})

const getStepsFromTemplate = stepsAttributes =>
  stepsAttributes?.map(step => ({
    ...step,
    id: null,
    template_step_id: step.name ? step.id : null,
    questions_attributes: step.questions_attributes.map(question => setTemplateQuestionId(question))
  }))

const setTemplateQuestionId = question => {
  const newQuestion = { ...question, id: null, template_question_id: question.id }
  if (question.children_attributes) {
    newQuestion.children_attributes = {}
    for (const key in question.children_attributes) {
      newQuestion.children_attributes[key] = question.children_attributes[key].map(children => ({
        ...children,
        id: null,
        template_question_id: children.id
      }))
    }
  }
  return newQuestion
}

const flatSubQuestionsAddPosition = (subQuestions, hasSubQuestions) => {
  if (!hasSubQuestions) return []
  return Object.values(subQuestions).reduce(
    (acc, subQuestionAlternative) => [
      ...acc,
      ...subQuestionAlternative.map((subQuestionAlternativeAtt, key) => ({
        ...subQuestionAlternativeAtt,
        position: key
      }))
    ],
    []
  )
}

const setQuestionPositions = questionsAttributes =>
  questionsAttributes.map((question, index) => ({
    ...question,
    position: index,
    children_attributes: flatSubQuestionsAddPosition(question.children_attributes, question.has_sub_questions)
  }))

const setupToSubmit = stepsAttributes =>
  stepsAttributes?.map((step, index) => ({
    ...step,
    position: index,
    questions_attributes: setQuestionPositions(step.questions_attributes)
  }))

const setupSerializedFormData = values => {
  const serializedData = serialize(
    {
      ...values,
      steps_attributes: setupToSubmit(values?.steps_attributes)
    },
    { indices: true }
  )
  return serializedData
}

const getPageDescription = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  if (canFilterByInstitution) return 'Selecciona la entidad cuyos formularios quieres ver'

  if (canFilterByBranchOffice) return 'Selecciona la sede cuyos formularios quieres ver'

  if (canFilterAndHasNotBranchOffices) return 'Estos son los formularios de tu institución.'

  if (canSeeBranchRecords) return 'Estos son los formularios de tu sede.'

  return 'Estos son los formularios a los que tienes acceso.'
}

const createFormHint = {
  new: 'Configura y agrega las preguntas de tu formulario.',
  template: 'Revisa, edita o agrega preguntas a la plantilla según lo requiera tu formulario.'
}

export {
  firstFormDate,
  getConfig,
  getInstructions,
  initialValuesFromTemplate,
  setupSerializedFormData,
  getStepsFromTemplate,
  getPageDescription,
  steps,
  createFormHint
}
