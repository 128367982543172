import React, { useRef } from 'react'
import { Form } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { Button } from '../../atoms/Button'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import { FORM_ERROR } from 'final-form'
import { useAuth } from '../../molecules/Auth'
import login from 'images/login.svg'
import { PasswordField } from '../../molecules/fields/PasswordField'
import ErrorValidationMessage from '../../molecules/ErrorValidationMessage'
import ReCAPTCHAField from '../../organisms/question_fields/ReCAPTCHAField'
import PublicFooter from '../../organisms/footer/PublicFooter'

const loginMutation = values => axios.post('/admin/v1/sessions.json', values)

const Login = () => {
  const history = useHistory()
  const location = useLocation()
  const { getUser } = useAuth()
  const recaptchaRef = useRef(null)

  const handleSubmit = async values => {
    try {
      const recaptcha = await recaptchaRef.current.executeAsync()
      await loginMutation({ ...values, recaptcha })
      await getUser()
      if (location.pathname === '/login') history.push('/formularios')
      localStorage.setItem('showFormStartMessage', 'true')
    } catch (error) {
      recaptchaRef.current.reset()
      return { [FORM_ERROR]: error.response.data.error }
    }
  }

  return (
    <>
      <div className="flex-1 md:flex items-center">
        <div className="md:w-1/2 px-6 md:py-28 py-10">
          <section className="lg:max-w-md md:m-auto" aria-labelledby="welcome-label">
            <h1 id="welcome-label" className="font-bold text-3xl md:text-4xl">
              ¡Te damos la bienvenida a Facilita Perú!
            </h1>
            <p className="w-full md:mb-10 text-base mt-2 md:mt-4">
              Ingresa y empieza a crear tus formularios de manera fácil.
            </p>
            <div className="justify-end mb-6 md:mb-0 ml-10 hidden md:flex">
              <img className="w-2/3 md:w-full h-auto" src={login} aria-hidden="true" alt="login" />
            </div>
          </section>
        </div>
        <div className="md:w-1/2 px-6 pt-8 md:py-16 bg-neutral-100 md:top-0 md:flex items-center justify-center">
          <div className="m-auto">
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit, submitError, submitting, pristine }) => (
                <form
                  onSubmit={handleSubmit}
                  className="bg-white p-6 lg:p-10 lg:max-w-100 shadow-card-login items-center justify-center"
                  aria-labelledby="sign-in-label"
                >
                  <h2 id="sign-in-label" className="font-bold text-xl mb-6">
                    Inicia sesión con tu cuenta de Gob.pe
                  </h2>
                  <TextField name="email" label="Correo electrónico" size="full" labelClassName="text-base" />
                  <PasswordField name="password" label="Contraseña" size="full" />
                  {submitError && <ErrorValidationMessage className="max-w-fit mb-6" error={submitError} />}
                  <Button disabled={submitting || pristine} type="submit" size="full" className="m-auto">
                    Ingresar
                  </Button>
                  <div className="mt-6 lg:max-w-md text-center">
                    <p className="text-base mb-2">¿Olvidaste tu clave?</p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-bold"
                      href="https://www.gob.pe/admin/password/new.user"
                    >
                      Recupérala en Gob.pe
                    </a>
                  </div>
                  <div className="flex justify-center">
                    <ReCAPTCHAField name="recaptcha" ref={recaptchaRef} />
                  </div>
                </form>
              )}
            </Form>
            <div className="mt-8 lg:max-w-md text-center pb-10 md:pb-0">
              <p>¿No tienes acceso a Facilita Perú?</p>
              <a target="_blank" rel="noopener noreferrer" className="font-bold" href="https://www.gob.pe/10899">
                Solicita acceso
              </a>
            </div>
          </div>
        </div>
      </div>
      <PublicFooter />
    </>
  )
}

export default Login
